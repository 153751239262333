/*SLIDESHOW CSS*/
/* Animations */
@keyframes prevButtonAni {
    from {background-image: linear-gradient(to left,
      rgb(167, 210, 245, 0),
      rgb(167, 210, 245, 0));}
    to {background-image: linear-gradient(to left,
      rgb(167, 210, 245, 0),
      rgb(167, 210, 245, 0.5));}
  }
  
  .slideshow {
    display: grid;
    justify-items: start;
    align-items: center;
    height: auto;
    width: 100%;
  }
  
  .slideshow >* {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  
  .slideshowButtons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .imgContainer {
    width: 100%;
    height: 100%;
  }
  
  .imgContainer img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  
  .nextButton {
    cursor: pointer;
    user-select: none;
  
    padding-right: 30px;
    height: 100%;
    width: 50%;
  
    z-index: 2;
    display: flex;
    justify-content: right;
    align-items: center;
  
    background-image: linear-gradient(to right, rgb(167, 210, 245, 0), rgb(167, 210, 245, 0));
  }
  
  .nextButton:hover {
    background-image: linear-gradient(to right, rgb(167, 210, 245, 0), rgb(167, 210, 245, 0.5));
  }
  
  .prevButton {
    cursor: pointer;
    user-select: none;
  
    padding-left: 30px;
    height: 100%;
    width: 50%;
  
    z-index: 2;
    display: flex;
    justify-content: left;
    align-items: center;
  
    background-image: linear-gradient(to left,
      rgb(167, 210, 245, 0),
      rgb(167, 210, 245, 0));
  }
  
  .prevButton:hover {
    background-image: linear-gradient(to left,
      rgb(167, 210, 245, 0),
      rgb(167, 210, 245, 0.5));
  }
  
  .pointerBackground {
    background-color: rgba(245, 245, 245, 0.67);
    border-radius: 50%;
  
    height: 30px;
    width: 30px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* TODO fix about us:
  - add the about us content
  
  */