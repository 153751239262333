.aboutUsPage {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.testBox {
    height: 100%;
    width: 70%;
    background-color: blueviolet;
}

.aboutUsNav {
    height: 100%;
    width: 20%;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* TODO remove this CSS after done testing */
.theBigTestDiv {
    height: 2000px;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    background-color: aquamarine;
}