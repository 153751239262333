* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}


/*HEADER CSS*/
header {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  height: 6em;
}

.logo_container {
  display: flex;
  width: 40%;
  align-items: center;
}

.logo_container img {
  width: 20%;
}

.logo_container h1 {
  width: 70%;
  text-align: left;
  font-size: xx-large;
  font-family: 'Kanit', sans-serif;
  font-style: italic;
  color: rgb(37, 87, 162);
}

.nav_container {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  align-items: center;
}

.nav_container a {
  /*Box Formatting*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  
  /*Font Formatting*/
  font-size: larger;
  font-family: 'Kanit', sans-serif;
  color: rgb(37, 87, 162);
}

.nav_container a:hover {
  background-image: linear-gradient(to bottom, whitesmoke, rgb(232, 237, 240), rgb(213, 224, 231))
}